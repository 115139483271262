<template>
  <div class="patient-list-page w-100">
    <basic-subheader title="Danh mục các bệnh nhân"> </basic-subheader>
    <b-container fluid class="patient-list-page__body mb-6 mt-6">
      <b-row>
        <b-col>
          <div class="card card-custom">
            <div class="card-body mt-0">
              <template-table
                :column="column"
                :data="data"
                :paging="paging"
                :tableAction="false"
                :selectAction="false"
                @search="searchRequest"
                @reset="resetRequest"
                @sortBy="sortRequest"
              >
                <template v-slot:form>
                  <div class="row mb-0">
                    <div class="col-lg-3 mb-lg-0 mb-0">
                      <basic-input
                        label="Tên bệnh nhân:"
                        placeholder="Nhập tên bệnh nhận"
                        name="name"
                        :value.sync="filter.searchKey"
                      ></basic-input>
                    </div>
                  </div>
                </template>
                <template v-slot:body="{ item }">
                  <td>
                    {{
                      getFullName(
                        item.lastName,
                        item.middleName,
                        item.firstName,
                      )
                    }}
                  </td>
                  <td>
                    {{ item.phoneNumber }}
                  </td>
                  <td>
                    {{ item.dateOfBirth }}
                  </td>
                  <td><user-gender :gender="item.gender" /></td>
                  <td>
                    <diabetes-state :diabetesState="item.diabetesState" />
                  </td>
                  <td>
                    {{ item.diabetesDate }}
                  </td>
                </template>
              </template-table>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  data() {
    return {
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
      filter: {
        searchKey: null,
      },
      sort: {
        by: null,
        order: null,
      },
      column: [
        {
          key: 'fullName',
          label: 'Tên Bệnh Nhân',
          sortable: false,
        },
        {
          key: 'phoneNumber',
          label: 'Số Điện Thoại',
          sortable: false,
        },
        {
          key: 'dateOfBirth',
          label: 'Ngày Sinh',
          sortable: false,
        },
        {
          key: 'gender',
          label: 'Giới Tính',
          sortable: false,
        },
        {
          key: 'diabetesStatus',
          label: 'Trạng Thái',
          sortable: false,
        },
        {
          key: 'diabetesDate',
          label: 'Ngày Phát Hiện',
          sortable: false,
        },
      ],
      data: [],
    };
  },
  computed: {
    searchParams() {
      return {
        searchKey: this.filter.searchKey,
        page: this.paging.page,
        size: this.paging.pageSize,
      };
    },
  },
  watch: {
    'paging.page'() {
      this.loadData();
    },
    'paging.pageSize'() {
      this.loadData();
    },
    sort: {},
  },
  methods: {
    getFullName(lastName, middleName, firstName) {
      return [lastName, middleName, firstName].join(' ');
    },
    pagingAction() {
      this.loadData();
    },
    // createItem() {},
    // editItem() {},
    // viewItem(item) {
    // },
    // copyItem(item) {
    // },
    // deleteItem(item) {
    // },
    searchRequest() {
      this.loadData();
    },
    resetRequest() {
      this.filter.searchKey = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
      this.loadData();
    },
    sortRequest() {
      return;
    },
    loadData() {
      this.$store.commit('context/setLoading', true);
      this.$api
        .get('patient', this.searchParams)
        .then(({ meta, data }) => {
          this.data = data || [];
          this.paging.total = meta.total;
        })
        .finally(() => {
          this.$store.commit('context/setLoading', false);
        });
      return;
    },
  },
  mounted() {
    this.loadData();
  },
};
</script>
